/* eslint-disable camelcase */
import formatCurrency from 'lib/format-currency';
import { createSelector } from 'reselect';
import colors from 'theme/charts/colors';
import translations from 'translations';
import configSelector from './config';

export default createSelector(
  (state) => state.aggr.payload,
  (_, key) => key,
  configSelector,
  (payload, key, config) => {
    const { languageCode } = config;
    const pieChartData = [
      {
        label: 'audiobook',
        growthRates: null,
        percent: 0,
        value: null,
        color: colors.item_type[0],
      },
      {
        label: 'ebook',
        growthRates: null,
        percent: 0,
        value: null,
        color: colors.item_type[1],
      },
      {
        label: 'print',
        growthRates: null,
        percent: 0,
        value: null,
        color: colors.item_type[2],
      },
    ];

    let currentTotal = 0;
    let previousTotal = 0;

    const result = {
      pieChartData,
      value: null,
      percent: null,
      isWaiting: true,
    };

    if (!payload[key] || !payload[key].data) return result;

    const {
      isWaiting,
      currency,
      query,
      data: {
        aggregations: {
          results: { buckets },
        },
      },
    } = payload[key];

    const dataType = (query.match(/dataType=(\w+)/)?.[1]) ?? 'temporary_data';

    if (dataType === 'usage_data') result.pieChartData = result.pieChartData.filter(({ label }) => label !== 'print');

    // ADD percent ( diff with prev period ) and value
    result.pieChartData = result.pieChartData.map((asset) => {
      const bucket = buckets.find(({ key: k }) => asset.label === k);

      if (!bucket)
        return {
          ...asset,
          growthRates: 0,
          currentValue: 0,
          value: dataType === 'usage_data' ? `0 ${translations[languageCode].hours}` : (dataType !== 'usage_data' && currency === 'eur') ? '0,00 €' : '$0.00',
        };

      let {
        current_period: {
          [dataType === 'usage_data' ? 'total_duration' : 'total_revenues']: { value: currentValue },
        },
        previous_period: {
          [dataType === 'usage_data' ? 'total_duration' : 'total_revenues']: { value: previousValue },
        },
      } = bucket;

      currentValue = dataType === 'usage_data' ? parseFloat((currentValue / 60)) : currentValue;
      previousValue = dataType === 'usage_data' ? parseFloat((previousValue / 60)) : previousValue;

      currentTotal += currentValue;
      previousTotal += previousValue;

      let growthRates = 0;

      if (previousValue !== 0) {
        growthRates = Math.round(((currentValue - previousValue) / previousValue) * 100);
      }

      const value = dataType === 'usage_data' ? `${currentValue.toFixed(2)} ${translations[languageCode].hours}` : formatCurrency(currency, currentValue);

      return {
        ...asset,
        growthRates,
        value,
        currentValue,
      };
    });

    // ADD percentPie ( percent of the pie chart )
    result.pieChartData = result.pieChartData.map((asset) => {
      const bucket = buckets.find(({ key: k }) => asset.label === k);

      if (!bucket) return asset;

      const {
        current_period: {
          [dataType === 'usage_data' ? 'total_duration' : 'total_revenues']: { value: currentValue },
        },
      } = bucket;

      let percent = 0;

      if (currentTotal !== 0) {
        percent = Math.round(currentValue / (currentTotal / 100));
        if (dataType === 'usage_data') percent = Math.round((currentValue / 60) / (currentTotal / 100));
      }

      return {
        ...asset,
        percent,
      };
    });

    // Sort by currentValue
    result.pieChartData.sort((a, b) => a.currentValue - b.currentValue).reverse();

    // ADD color
    result.pieChartData = result.pieChartData.map((pieData, index) => ({
      ...pieData,
      color: colors.item_type[index],
    }));

    result.value = formatCurrency(currency, currentTotal);

    if (previousTotal !== 0) {
      result.percent = Math.round(((currentTotal - previousTotal) / previousTotal) * 100);
    } else {
      result.percent = 0;
    }

    return { ...result, isWaiting };
  },
);
