import React, { useState, useContext, useMemo, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { VictoryPie } from 'victory';

const Wrapper = styled.svg`
  position: absolute;
  top: ${({ position }) => position}px;
  left: ${({ position }) => position}px;
`;

function Circle({ selected, index, data, maxWidth, ratio, radius }) {
  const [percent, setPercent] = useState(0);

  const width = useMemo(() => maxWidth - ratio * index, [maxWidth, ratio, index]);

  const calculateRadius = useMemo(() => width / 2 - 10, [width]);

  const calculatePercent = useMemo(() => 100 - percent, [percent]);

  const calculatePosition = useMemo(() => (ratio * index) / 2, [ratio, index]);

  const theme = useContext(ThemeContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPercent(data.percent);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [data.percent]);

  return (
    <Wrapper
      viewBox={`0 0 ${width} ${width}`}
      width={width}
      height={width}
      position={calculatePosition}
    >
      <VictoryPie
        padding={0}
        standalone={false}
        width={width}
        height={width}
        labels={() => ''}
        animate={{
          duration: 2000,
        }}
        data={[
          { x: 1, y: percent },
          { x: 2, y: calculatePercent },
        ]}
        innerRadius={calculateRadius}
        cornerRadius={radius}
        style={{
          data: {
            fill: selected
              ? themeGet('colors.accent')({ theme })
              : themeGet(`colors.${data.color}`)({ theme }),
            fillOpacity: ({ datum }) => (datum.x === 1 ? 1 : 0.1),
          },
        }}
      />
    </Wrapper>
  );
}

export default Circle;
