/* eslint-disable camelcase */
import formatCurrency from 'lib/format-currency';
import { createSelector } from 'reselect';

export default createSelector(
  (state) => state.count.payload,
  (_, key) => key,
  (payload, key) => {
    let percent = 0;
    const card = payload[key];

    if (!card || !card.data) return { percent, value: null };

    const {
      currency,
      data: {
        aggregations: { current_period, previous_period },
      },
    } = card;

    const currentRevenues = current_period.total_revenues.value;
    const previousRevenues = previous_period.total_revenues.value;

    percent = 100;

    if (previousRevenues !== 0) {
      percent = Math.round(((currentRevenues - previousRevenues) / previousRevenues) * 100);
    }

    if (previousRevenues === 0 && currentRevenues === 0) percent = 0;

    const value = formatCurrency(currency, currentRevenues);
    const previousValue = formatCurrency(currency, previousRevenues);

    return {
      percent,
      value,
      previousValue,
    };
  },
);
