export default {
  item_type: {
    0: 'blue_500',
    1: 'blue_400',
    2: 'blue_300',
  },
  business_revenues: {
    0: 'pie_1_0',
    1: 'pie_1_1',
    2: 'pie_1_2',
    3: 'grey_400',
    4: 'pie_1_3',
    5: 'primary_200',
  },
  store_name: {
    0: 'pie_0_0',
    1: 'pie_0_1',
    2: 'pie_0_2',
    3: 'pie_0_3',
    4: 'pie_0_4',
    5: 'pie_0_5',
  },
  title: {
    0: 'pie_1_0',
    1: 'pie_1_1',
    2: 'pie_1_2',
    3: 'pie_1_3',
    4: 'pie_1_4',
    5: 'pie_1_5',
  },
  main_category: {
    0: 'pie_2_0',
    1: 'pie_2_1',
    2: 'pie_2_2',
    3: 'pie_2_3',
    4: 'pie_2_4',
    5: 'pie_2_5',
  },
};
