/* eslint-disable camelcase */
import { createSelector } from 'reselect';
import moment from 'moment';
import translations from 'translations';
import configSelector from './config';
import { formatDateString } from '../../lib/utils';

const selectAreaData = createSelector(
  (state) => state.histogram.payload,
  (_, key) => key,
  (payload, key) => payload[key],
);

export default createSelector(
  selectAreaData,
  configSelector,
  (payload, { languageCode, currency }) => {
    const result = { chart: [] };

    if (!payload || !payload.aggregations) return result;

    const {
      aggregations: {
        current_histogram: {
          current_period: { buckets },
        },
      },
      isWithinRange = null,
    } = payload;

    let previousBuckets = [];
    let prevQuantity = null;
    let prevRevenue = null;
    let prevDuration = null;

    if (payload?.aggregations?.previous_histogram?.previous_period?.buckets) {
      ({
        aggregations: {
          previous_histogram: {
            previous_period: { buckets: previousBuckets },
          },
        },
      } = payload);

      prevQuantity = {
        id: 'prev_quantity',
        label: translations[languageCode].prevquantity,
        color: 'lime_400',
        data: [],
        currency,
        maxAxisY: 0,
      };

      prevRevenue = {
        id: 'prev_revenue',
        label: translations[languageCode].prevrevenue,
        color: 'lime_300',
        data: [],
        currency,
        maxAxisY: 0,
      };

      prevDuration = {
        id: 'prev_duration',
        label: translations[languageCode].prevhours,
        color: 'pie_2_1',
        data: [],
        maxAxisY: 0,
      };
    }

    const quantity = {
      id: 'quantity',
      label: translations[languageCode].quantity,
      color: 'area_0',
      data: [],
      currency,
      opacity: 0.65,
      maxAxisY: 0,
    };

    const revenue = {
      id: 'revenue',
      label: translations[languageCode].revenue,
      color: 'area_1',
      data: [],
      currency,
      opacity: 0.65,
      maxAxisY: 0,
    };

    const duration = {
      id: 'duration',
      label: translations[languageCode].hours,
      color: 'pie_2_0',
      data: [],
      opacity: 0.65,
      maxAxisY: 0,
    };

    buckets.forEach(
      ({ key_as_string, total_revenues, total_quantity, total_duration }) => {
        moment.locale(languageCode);
        const date = moment(key_as_string).format(formatDateString[languageCode]);
        const timestamp = moment(key_as_string).utc().format();

        if (Math.round(total_quantity.value) > quantity.maxAxisY) {
          quantity.maxAxisY = Math.round(total_quantity.value);
        }

        quantity.data.push({
          timestamp,
          date,
          y: Math.round(total_quantity.value),
        });

        if (Math.round(total_revenues.value) > revenue.maxAxisY) {
          revenue.maxAxisY = Math.round(total_revenues.value);
        }

        revenue.data.push({
          timestamp,
          date,
          y: Math.round(total_revenues.value),
        });

        duration.data.push({
          timestamp,
          date,
          y: Math.round(total_duration.value / 60),
        });

        if (Math.round(total_duration.value) > duration.maxAxisY) {
          duration.maxAxisY = Math.round(total_duration.value);
        }
      },
    );

    result.chart = [quantity, revenue, duration];

    if (previousBuckets.length) {
      let i = 0;
      previousBuckets.forEach(
        ({ total_revenues, total_quantity, total_duration }) => {
          moment.locale(languageCode);
          const date = buckets[i] ? moment(buckets[i].key_as_string).format(formatDateString[languageCode]) : null;
          const timestamp = buckets[i] ? moment(buckets[i].key_as_string).utc().format() : null;

          i += 1;

          if (Math.round(total_quantity.value) > prevQuantity.maxAxisY) {
            prevQuantity.maxAxisY = Math.round(total_quantity.value);
          }

          prevQuantity.data.push({
            timestamp,
            date,
            y: Math.round(total_quantity.value),
          });

          if (Math.round(total_revenues.value) > prevRevenue.maxAxisY) {
            prevRevenue.maxAxisY = Math.round(total_revenues.value);
          }

          prevRevenue.data.push({
            timestamp,
            date,
            y: Math.round(total_revenues.value),
          });

          prevDuration.data.push({
            timestamp,
            date,
            y: Math.round(total_duration.value / 60),
          });

          if (Math.round(total_duration.value) > result.maxAxisY) {
            result.maxAxisY = Math.round(total_duration.value);
          }
        },
      );

      result.chart = [...result.chart, prevQuantity, prevRevenue, prevDuration]
    }

    result.isWithinRange = isWithinRange;

    return result;
  },
);
