import React, { useContext, useCallback } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const Wrapper = styled.div`
  position: absolute;
  top: 180px;
  left: 0;
  width: 100%;
  height: 65px;

  display: ${({ isTooltipShow }) => (isTooltipShow ? 'flex' : 'none')};
  justify-content: center;

  & > svg {
    width: 200px;
  }
`;

function Label({ data, isTooltipShow }) {
  const formattedLabel = useCallback((label) => {
    if (label.length > 19) {
      const cuttedString = label.substring(0, 19);

      return `${cuttedString}...`;
    }

    return label.toLowerCase();
  }, []);

  const theme = useContext(ThemeContext);

  return (
    <Wrapper isTooltipShow={isTooltipShow}>
      <svg width="200" height="65" viewBox="0 0 200 65" xmlns="http://www.w3.org/2000/svg">
        <rect x="0" width="200" height="65" fill="white" />
        {data.map(({ color, label, percent }, index) => (
          <g key={color}>
            <circle
              cx="20"
              r="5"
              fill={themeGet(`colors.${color}`)({ theme })}
              cy={12 + index * 20}
            />
            <text
              width="40"
              fill="#344244"
              style={{
                whiteSpace: 'pre',
                fontFamily: 'inherit',
                fontSize: '12',
                letterSpacing: '0px',
              }}
            >
              <tspan x="35" y="17" dy={index * 20}>
                {`${formattedLabel(label)}`}
              </tspan>
              <tspan textAnchor="end" x="190" y="17" dy={index * 20}>
                {`${percent}%`}
              </tspan>
            </text>
          </g>
        ))}
      </svg>
    </Wrapper>
  );
}

export default Label;
