import React from 'react';

function Plus({ width }) {
  return (
    <svg width={width || '18'} viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 10.2271H10V16.2271H8V10.2271H2V8.22705H8V2.22705H10V8.22705H16V10.2271Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Plus;
