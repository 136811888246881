/* eslint-disable camelcase */
import moment from 'moment';
import { createSelector } from 'reselect';
import translations from 'translations';
import formatCurrency from 'lib/format-currency';
import configSelector from './config';

export const selectTotalUnits = createSelector(
  (state) => state.count.payload,
  (_, key) => key,
  (payload, key) => {
    if (!payload[key] || !payload[key].data) return { quantity: null, unique: null };

    const {
      data: {
        aggregations: { current_period },
      },
    } = payload[key];

    const quantityTotal = current_period.total_quantity.value;
    const uniqueTotal = current_period.unique_titles.value;

    return {
      quantity: quantityTotal,
      unique: uniqueTotal,
    };
  },
);

const selectUnitsFilteredByKey = createSelector(
  (state) => state.units.payload,
  (_, key) => key,
  (payload, key) => {
    if (!payload[key] || !payload[key].data) return { total: null, units: [] };

    const {
      currency,
      data: {
        hits: { total, hits },
      },
    } = payload[key];

    total = total.value;

    const units = hits.map((hit) => {
      const {
        _id: id,
        _source: {
          store_name,
          store_partner_name,
          isbn,
          title,
          language,
          authors,
          main_category,
          cover_link,
          price_eur,
          price_usd,
          country,
          publisher,
          item_type,
          date_order,
          date_publisher,
          platform_name,
          revenues_eur = {},
          revenues_usd = {},
          reading_minutes,
          collections,
          supplier,
          order_type,
          quantity,
          data_type,
          public_domain,
        },
      } = hit;

      return {
        id,
        cover: cover_link,
        title,
        authors,
        genre: main_category,
        type: item_type,
        language,
        publisher,
        publishDate: date_publisher,
        orderDate: date_order,
        isbn,
        price: {
          eur: price_eur,
          usd: price_usd,
        },
        serie: collections,
        revenues: {
          eur: revenues_eur?.supplier || 0,
          usd: revenues_usd?.supplier || 0,
        },
        reading_minutes,
        store: store_name,
        storePartner: store_partner_name,
        platform: platform_name,
        country,
        supplier,
        orderType: order_type.toLowerCase(),
        quantity,
        dataType: data_type,
        publicDomain: public_domain,
        itemType: item_type,
      };
    });

    return { currency, units, total };
  },
);

export const selectUnitsList = createSelector(
  configSelector,
  selectUnitsFilteredByKey,
  (config, payload) => {
    let units = {};

    payload.units.forEach(
      ({
        id,
        genre,
        authors,
        language,
        publishDate,
        orderDate,
        price,
        revenues,
        reading_minutes,
        country,
        serie,
        ...others
      }) => {
        const { languageCode } = config;
        const { currency } = payload;

        moment.locale(languageCode);

        const formattedPrice = price[currency]
          ? formatCurrency(currency, Number(price[currency]))
          : translations[languageCode]['n.a.'];
        const formattedEarning = formatCurrency(currency, Number(revenues[currency]));

        const capitalAuthors =
          authors && authors.length
            ? authors.map((author) => {
                const authorSplit = author.split(' ');

                const capitalName = authorSplit.map(
                  (name) => `${name.charAt(0).toUpperCase()}${name.slice(1)}`,
                );

                return capitalName.join(' ');
              })
            : [];

        const countryCode = country.code;

        units = {
          ...units,
          [id]: {
            ...others,
            key: id,
            id,
            authors: capitalAuthors.join(', '),
            genre: genre && genre[languageCode] ? genre[languageCode] : null,
            language: language && language[languageCode] ? language[languageCode] : null,
            publishDate: moment(publishDate).format('DD MMM YYYY'),
            orderDate: moment(orderDate).format('DD MMM YYYY'),
            country: country[languageCode],
            duration: `${(reading_minutes / 60).toFixed(2)}h`,
            countryCode,
            countryObj: {
              label: country[languageCode],
              code: countryCode ? countryCode.toLowerCase() : null,
            },
            price: formattedPrice,
            earning: formattedEarning,
            serie: serie && serie.lenght ? serie.join(' - ') : null,
          },
        };
      },
    );

    return { ...payload, units };
  },
);

export const selectUnitsListArray = createSelector(selectUnitsList, (payload) => {
  const units = Object.keys(payload.units).map((key) => payload.units[key]);

  return { ...payload, units };
});
