import React from 'react';
import styled from 'styled-components';
import { space, color } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import Text from 'theme/text';
import Alert from 'theme/icons/alert';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > span {
    display: flex;
    align-items: center;
    color: ${themeGet('colors.warning')};
  }

  ${space}
  ${color}
`;

function AlertMessage({ message, style }) {
  return (
    <Wrapper {...style}>
      <span>
        <Alert />
      </span>
      <Text fontSize="h4" lineHeight="h4" fontWeight="medium" color="grey_600" ml="xs">
        {message}
      </Text>
    </Wrapper>
  );
}

export default AlertMessage;
