import React, { useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { useOutsideClick } from 'hooks';
import ContentLoader from 'theme/content-loader';
import Circle from './circle';
import Label from './label';

const Wrapper = styled.div`
  outline: 0;
`;

const WrapCircle = styled.div`
  ${space}

  position: relative;
  outline: 0;
`;

function Pie({ isLoading, style, selected, isTooltipVisible, data, maxWidth = 200 }) {
  const [isTooltipShow, setTooltipShow] = useState(false);
  const ratio = useRef(25);
  const radius = useRef(50);
  const ref = useRef();

  useOutsideClick(ref, () => {
    setTooltipShow(false);
  });

  const handleTooltipShow = useCallback(() => {
    if (isTooltipVisible) {
      setTooltipShow(true);
    }
  }, [isTooltipVisible]);

  const handleTooltipHidden = useCallback(() => {
    setTooltipShow(false);
  }, []);

  const handleKeyPress = useCallback(
    (event) => {
      if (event.charCode === 13) handleTooltipShow();
    },
    [handleTooltipShow],
  );

  return (
    <Wrapper
      ref={ref}
      role="button"
      tabIndex="0"
      style={{
        width: `${maxWidth}px`,
        height: `${maxWidth}px`,
      }}
      onClick={handleTooltipShow}
      onKeyPress={handleKeyPress}
      onMouseEnter={handleTooltipShow}
      onMouseLeave={handleTooltipHidden}
      {...style}
      aria-labelledby="label-pie-chart"
    >
      <Label id="label-pie-chart" aria-label="Pie chart" isTooltipShow={isTooltipShow} data={data} />
      {isLoading ? (
        <ContentLoader
          width={maxWidth}
          height={maxWidth}
          lines={[
            {
              x: 0,
              y: 0,
              rx: maxWidth,
              ry: maxWidth,
              width: maxWidth,
              height: maxWidth,
            },
          ]}
        />
      ) : (
        <WrapCircle>
          {data.map((el, index) => (
            <Circle
              key={el.color}
              data={el}
              index={index}
              maxWidth={maxWidth}
              ratio={ratio.current}
              radius={radius.current}
              selected={selected === el.color}
            />
          ))}
        </WrapCircle>
      )}
    </Wrapper>
  );
}

export default Pie;
