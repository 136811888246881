import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import ContentLoader from 'react-content-loader';

function AreaContentLoader({ speed, backgroundColor, foregroundColor }) {
  const theme = useContext(ThemeContext);

  return (
    <ContentLoader
      speed={speed || 2}
      width="100%"
      height="250"
      viewBox="0 0 5000 200"
      backgroundColor={backgroundColor || themeGet(`colors.primary_100`)({ theme })}
      foregroundColor={foregroundColor || themeGet(`colors.primary_50`)({ theme })}
      preserveAspectRatio="none"
    >
      <path d="M.12 200.858V18.554c157.456 11.248 527.557 41.165 842.245 54.732 324.305 13.982 607.945 18.519 842.245 18.519 234.31 0 477.05-14.612 811.05-27.984 322.28-12.902 662.56-24.533 828.88-27.16 338.68-5.35 629.23-6.255 828.88-9.877 199.64-3.621 647.65-18.793 846.7-25.926v200H.12z" />
    </ContentLoader>
  );
}

export default AreaContentLoader;
